<template>
  <button
    v-if="$showDevThings"
    :class="['dev-btn', { 'cy-icon-btn--small': !!iconName, 'dev-btn--icon': isIcon }]">
    <span class="dev-btn__dev-icon">🦄</span>
    <v-icon v-if="iconName">
      {{ iconName }}
    </v-icon>
    <span v-if="wrapDevText">// DEV ONLY: <slot/> //</span>
    <span v-else><slot/></span>
  </button>
</template>

<script>
export default {
  name: 'CyDevBtn',
  props: {
    iconName: {
      type: String,
      default: '',
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    wrapDevText: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
  $background: (
    icon: none,
    normal: white,
    inverse: get-color("dev-mode", "rainbow")
  );
  $text: (
    normal: get-color("dev-mode", "pink"),
    inverse: white
  );
  $border: (
    normal: get-color("dev-mode", "pink"),
    inverse: white
  );

  .dev-btn {
    display: flex;
    position: relative;
    flex: 1 0 auto;
    height: auto;
    padding: 0.25em 1em;
    border: 2px solid map.get($border, "normal");
    border-radius: 2em;
    background: map.get($background, "normal");
    box-shadow: 0 0 4px 1px get-color("black", $alpha: 0.8);
    color: map.get($text, "normal");
    font-weight: 800;

    &--icon {
      background: map.get($background, "icon");
    }

    &:hover {
      border-color: map.get($border, "inverse");
      color: map.get($text, inverse);

      @extend %rainbow-background;
    }

    &:focus {
      outline: none;
    }

    &__dev-icon {
      display: flex;
      position: absolute;
      top: -0.75em;
      left: -0.75em;
      align-items: center;
      justify-content: center;
      width: 2em;
      height: 2em;
      border: 2px solid white;
      border-radius: 50%;
      background: get-color("secondary");
      box-shadow: 0 0 4px 1px get-color("black", $alpha: 0.8);
      color: white;
      font-size: 0.85rem;
    }

    &:disabled {
      border-color: get-color("grey", "dark-1");
      background: get-color("grey");
      color: get-color("grey", "dark-2");

      .dev-btn__dev-icon {
        background: get-color("grey", "dark-2");
      }

      &:hover {
        text-shadow: none;
      }
    }
  }
</style>
